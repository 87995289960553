<template>
  <div class="content">
    <div class="top">
      <!-- <h1>校长信箱信件列表</h1> -->
      <p>我的信件</p>
    </div>
    <div class="main">
      <div class="mainContent">
        <div style="display: flex; justify-content: space-between; padding: 20px 0;">
          <el-select v-model="selectMessageType" placeholder="请选择">
            <el-option
              v-for="item in messageTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button style="margin-left: 1rem;" @click="messageTypeCallback" icon="el-icon-search"
            >搜索</el-button
          >
          <el-date-picker
            style="width: 300px;"
            v-model="startTimestamp"
            type="daterange"
            align="right"
            @change="startTimeCallback"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
          <el-button  type="success" plain @click="reset">重置</el-button>
        </div>
        <el-card class="tableBox">
          <el-table :data="tableData" style="width: 100%;" :header-cell-style="{color: '#000000'}">
            <el-table-column prop="messageTitle" label="标题" width="220px"></el-table-column>
            <el-table-column prop="creatTime" label="提交时间" width="160px"></el-table-column>
            <el-table-column prop="messageType" label="类型" width="80px">
              <template slot-scope="scope">
                <el-tag disable-transitions>{{ scope.row.messageType }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="replyTime" label="回复时间" width="160px"></el-table-column>
            <el-table-column label="操作" style="width: 70px;">
              <template slot-scope="scope">
                <el-button type="text" @click="openDetail(scope.$index, scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>
    <div class="pageBox">
      <el-pagination background layout="prev, pager, next" :total="tableData.length">
      </el-pagination>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" @click="jumpHome" class="sendBtn">返回首页</el-button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import axios from '../axios';
export default {
  name: 'EmailList',
  data() {
    return {
      dialogDetailVisible: false,
      dialogMsg: {},
      queryInfo: {
        pageSize: 5,
        pageNum: 1
      },
      messageTypeOptions: [],
      selectMessageType: '',
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      startTimestamp: ''
    };
  },
  computed: {
    timeName() {
      return (time) => {
        return time + '收到回复';
      };
    }
  },
  methods: {
    reset(){
      this.selectMessageType = '';
      this.getAllMessages();
    },
    getMore(index, row) {
      console.log(index, row);
      // this.dialogDetailVisible = true;
      this.dialogMsg.messageContent = row.messageContent;
      this.dialogMsg.replyContent = row.replyContent;
      this.dialogMsg.replyTime = row.replyTime;
    },
    jumpHome() {
      this.$router.push({
        name: 'IntroducePage'
      });
    },
    async getAllMessages() {
      let res = await axios.get('/mailbox/Visitor/Reception/findAllMessages', {
        params: {
          page: this.queryInfo.pageNum,
          size: this.queryInfo.pageSize
        }
      });
      if (res.code === 200) {
        this.tableData = res.object.list;
      }
    },
    async getAllMessageType() {
      try {
        let data = await axios({
          url: '/mailbox/Visitor/Reception/findAllTypes',
          method: 'GET',
          headers: {token: this.$store.state.token}
        });
        let tmpArr = data.object.map((item) => {
          return {value: item.typeName, label: item.typeName};
        });
        for (let i = 0; i < tmpArr.length; i++) {
          for (let j = i; i < tmpArr.length; i++) {
            if (tmpArr[i].value === tmpArr[j].value) {
              tmpArr.splice(j, 1);
            }
          }
        }
        this.messageTypeOptions = tmpArr;
      } catch (err) {
        console.log(err);
      }
    },
    async getMessagesByOpts({type, startTime, endTime}) {
      const params = {
        page: this.queryInfo.pageNum,
        size: this.queryInfo.pageSize
      };
      if (type) {
        params.type = type;
      }
      if (startTime) {
        params.startTime = startTime;
      }
      if (endTime) {
        params.endTime = endTime;
      }
      try {
        console.log(params);
        let data = await axios.get('/mailbox/Visitor/Reception/findSuchMessages', {
          params
        });
        console.log(data);
        if (data.code === 200) {
          this.tableData = data.object.list;
        } else if (data.message === '没有数据') {
          this.tableData = [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    messageTypeCallback() {
      this.getMessagesByOpts({type: this.selectMessageType});
    },
    getData(n) {
      n = new Date(n);
      return n.toLocaleDateString().replace(/\//g, '-') + ' ' + n.toTimeString().substr(0, 8);
    },
    startTimeCallback() {
      const startTime = this.getData(this.startTimestamp[0].getTime());
      const endTime = this.getData(this.startTimestamp[1].getTime());
      this.getMessagesByOpts({type: this.selectMessageType, startTime, endTime});
    },
    openDetail(index, row) {
      console.log(row);
      // this.$alert('这是一段内容', '标题名称', {
      //   confirmButtonText: '确定',
      //   callback: (action) => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${action}`
      //     });
      //   }
      // });
      const h = this.$createElement;
      this.$msgbox({
        // message: h('p', null, [
        //   h('span', null, '内容可以是 '),
        //   h('i', {style: 'color: teal'}, 'VNode')
        // ]),
        message: h('div', null, [
          h('h4', {style: 'margin-top:20px'}, '我的留言'),
          h('p', {style: 'margin-top:10px'}, `${row.messageContent}`),
          h('h4', {style: 'margin-top:10px'}, `${row.replyTime} 收到回复`),
          h('p', {style: 'margin-top:10px'}, `${row.replyContent}`)
        ]),
        showCancelButton: false,
        confirmButtonText: '确定'
      });
    }
  },
  mounted() {
    this.getAllMessages();
    this.getAllMessageType();
  }
};
</script>
<style scoped>
.family {
  font-family: "微软雅黑" !important;
  color: black !important;
}
</style>
<style lang="less" scoped>
.msgDetail {
  border-top: solid 1px #ededed;
  padding-top: 0px;
}
.el-timeline {
  padding-left: 2rem;
}
.pageBox {
  width: 100%;
  // margin-bottom: 10px;
  background-color: #fff;
}
.bottom-btn {
  width: 100px;
  margin: 15px auto;
}
.tableBox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.tableBox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.tableBox::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 20px;
  margin-top: 98px;
  // height: 830px;
  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }
  .top {
    height: 30px;
    width: 100%;
    p {
      text-align: center;
      width: 100%;
      line-height: 30px;
      font-weight: 600;
      margin: 0 auto;
      align-items: center;
      height: 100%;
      font-family: emoji;
    }
  }
  .main {
    flex: 1;
    padding: 1px 0;
    // height: 713px;
    // background-color: blue;
    .mainContent {
      padding: 10px 0;
      // height: 300px;
      // display: flex;
      // flex-direction: column;
      .tableBox {
        // height: 606px;
        overflow-y: auto;
      }
    }
  }
}
.el-card__body {
  padding: 20px 0;
}
</style>
